import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import DefaultPage from "../components/pages/DefaultPage";
import "./AusstellerFormularPage.scss";
import IframePage from "./IframePage/IframePage";

function AusstellerFormularPage({ }) {
    const [Url, setUrl] = useState(false)

    function handleClick(url) {
        setUrl(url)
    }

    // Bewerbungsphase: Ab dem Mittwoch um 9 Uhr (nach dem 2. Dienstag im Oktober) bis zum Jahresende.
    const isApplicationPeriod = useMemo(() => {
        const now = new Date()
        const year = now.getFullYear()
        // Erster Tag im Oktober (Monate sind 0-indexiert: 9 = Oktober)
        const octoberFirst = new Date(year, 9, 1)
        // Finde den ersten Dienstag im Oktober (0 = Sonntag, 1 = Montag, 2 = Dienstag, ...)
        const firstTuesdayOffset = (2 - octoberFirst.getDay() + 7) % 7
        const firstTuesday = new Date(octoberFirst)
        firstTuesday.setDate(octoberFirst.getDate() + firstTuesdayOffset)
        // Zweiter Dienstag ist 7 Tage nach dem ersten Dienstag
        const secondTuesday = new Date(firstTuesday)
        secondTuesday.setDate(firstTuesday.getDate() + 7)
        // Start am Mittwoch nach dem zweiten Dienstag um 9 Uhr
        const startDate = new Date(secondTuesday)
        startDate.setDate(secondTuesday.getDate() + 1)
        startDate.setHours(9, 0, 0, 0)
        // Ende des Jahres (31. Dezember)
        const endOfYear = new Date(year, 11, 31, 23, 59, 59, 999)
        return now >= startDate && now <= endOfYear
    }, [])

    if (Url) {
        return <IframePage url={Url} />
    }

    return (
        <DefaultPage uri="aussteller" className="AusstellerFormularPage" >
            <Helmet>
                <title>Bewerbung | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Bewerbungsformulare für den Krämermarkt und das Altstadtfest" />
            </Helmet>

            <div className="p-3 container">
                <h1>Bewerbungsformulare</h1>
                <div className="mb-5 pb-5">
                    {isApplicationPeriod ? (
                        <div className="mb-5">
                            <p>Hier finden Sie diverse Bewerbungsformulare für den Grünberger Krämermarkt und das Altstadtfest.</p>
                            {buttons.map((i, k) => <FormularItem {...i} key={k} onClick={handleClick} />)}
                        </div>
                    ) : (
                        <div>
                            Die Bewerbungsfrist ist bereits abgelaufen. Nachträgliche Bewerbungen senden Sie bitte an: <a href="mailto:e.epp@gruenberg.de">e.epp@gruenberg.de</a>
                        </div>
                    )}
                </div>
            </div>
        </DefaultPage>
    )
}
export default AusstellerFormularPage

function FormularItem({ url, title, onClick = () => { } }) {

    return <div className="FormularItem mb-4 col-12 col-lg-8 col-xl-5 col-xxl-4">
        <span>
            {title}
        </span>
        <button onClick={() => onClick(url)}>Jetzt bewerben</button>
    </div>
}

let buttons = [
    {
        title: "Krämermarkt",
        url: "https://gallusmarkt-hosting.hessenapp.de/gallusmarkt-formular-eejfhhrufhe78zh7dkdokdofkeokcowko0cjkpeiokfokeogjkr0ie202493do/"
    },
    {
        title: "Altstadtfest",
        url: "https://gallusmarkt-hosting.hessenapp.de/bewerbung-altstadtfest-fkeiuf7ehfz7g73z7z437zh74ufgzhfeklksh7h38k4ugf4hervjrhuff-rjfu4hrhf44-x8rj8ff-2025/"
    },
]